<template>
  <div class="Main">
    <div class="container">
      <div class="logo">
        <img src="../assets/imgs/100.png" />
      </div>
      <h1>{{ company }}</h1>
      <h1>底稿选择列表</h1>
      <ul>
        <li v-for="(item, index) in lists" :key="index">
          <span>{{ item.name }}</span>
          <router-link tag="a" target="_blank"
            :to="{
              name: item.page,
            }"
            >点击进入</router-link
          >
        </li> 
      </ul>
    </div>
  </div>
</template>

<script>  
window.localStorage.company = "🍬"
window.localStorage.jituan = "快猫文化传媒"

export default {
  name: "Main",
  data() {  
    return {
      company: window.localStorage.company,
      jituan:window.localStorage.jituan,
      lists: [
        { name: "※ 客户抢单后台弹窗", page: "Page1" },
        { name: "※ 客户违规操作", page: "Page2" },
        { name: "※ 客户连续违规操 作", page: "Page3" },
        { name: "※ 客户最后一次违规操作", page: "Page4" },
        { name: "※ 妹子信息预约图", page: "Page5" },
        { name: "※ 双方信息预约图", page: "Page6" },
        { name: "※ 客户信誉分问题", page: "Page7" },
        { name: "※ 客户IP地址问题", page: "Page8" },
        { name: "※ 客户补单金额错误弹窗", page: "Page9" },
        { name: "※ 客户开通大额通道", page: "Page10" },
        { name: "※ 白底盖章-垫付图-财务部", page: "Page11" },
        { name: "※ 白底盖章-违规图-数据部", page: "Page12" },
        { name: "※ 白底盖章-通知-行政部", page: "Page13" },
        { name: "※ 白底盖章-大额通道-财务部", page: "Page14" },
        { name: "※ 白底盖章-补单名额-数据部", page: "Page15" },
        { name: "※ 白底盖章-数据保留-数据部", page: "Page16" },
        { name: "※ 白底盖章-包赔协议-对名章", page: "Page21" },
        { name: "※ 白底盖章-保证金缴纳协议", page: "Page22" },
        { name: "※ 白底盖章-出款协议", page: "Page23" },
        { name: "※ 白底盖章-冻结出款协议", page: "Page24" },
        { name: "※ 白底盖章-律师涵", page: "Page25" },
        { name: "※ 转账冲正-对冲图", page:"Page26"},
        { name: "※ 约炮卡", page:"Page27"},
        // { name: "※ 约炮指南", page:"Page28"},
      ],
    };
  },
};
</script>

<style lang="less" scope>
h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.Main {
  background: url("../assets/imgs/bg.png") center center / cover no-repeat;
  height: 100vh;
  overflow-y: scroll;
  .container {
    width: 500px;
    margin: 20px auto;
    padding: 30px 0;
    background-color: #000000e6;
    border: 2px solid #fff;
    border-radius: 8px;
    color: #f000c7;
    .logo {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      border: 30px solid rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      background-color: #ffffff4d;
      img {
        width: 100px;
      }
    }
    h1 {
      font-size: 1.5em;
      margin-block-start: 0.83em;
      margin-block-end: 0.83em;
    }
    ul {
      padding: 0 10px;
      list-style: none;
      li {
        margin: 20px 0;
        text-align: -webkit-match-parent;
        span {
          display: inline-block;
          margin-right: 20px;
          color: #fff;
        }
        a {
          color: #f000c7;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
} 
</style>
 
